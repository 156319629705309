import React, { Component } from 'react'
import { Link } from 'gatsby'
import styles from './support.module.sass'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
   faQuestionCircle,
   faComments,
   faPhone,
   faPaperPlane,
   faCompass,
} from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'

class Support extends Component {
   // constructor() {
   //     super(props)
   // }

   render() {
      return (
         <section
            className={[
               styles.section__support,
               !!this.props.invertColors ? styles.invert : '',
            ].join(' ')}>
            {this.props.methods ? (
               <>
                  {this.props.methods.includes('faq') && (
                     <div className={styles.method}>
                        <h3>FAQs</h3>
                        <p>
                           We've compiled a list of some of the most common
                           questions we encounter.
                        </p>
                        <div className={styles.action}>
                           <Link
                              to="/faq/"
                              className="button button--primary button--small button--has-icon button--has-icon">
                              Check Out the List <span>&rarr;</span>
                           </Link>
                        </div>
                        <div className={styles.icon}>
                           <FontAwesomeIcon
                              icon={faQuestionCircle}
                              fixedWidth
                           />
                        </div>
                     </div>
                  )}

                  {this.props.methods.includes('chat') && (
                     <div className={styles.method}>
                        <h3>Live Chat</h3>
                        <p>
                           If you have a general question or would like to
                           schedule an appointment, you can live chat with one
                           of our financial advisors.
                        </p>
                        <div
                           className={styles.action}
                           dangerouslySetInnerHTML={{
                              __html: `
                                 <a
                                    href="#"
                                    onClick="LC_API.open_chat_window();return false;"
                                    class="button button--white button--small button--has-icon button--has-icon">
                                    Let's Chat <span>&rarr;</span>
                                 </a>
                              `,
                           }}></div>
                        <div className={styles.icon}>
                           <FontAwesomeIcon icon={faComments} fixedWidth />
                        </div>
                     </div>
                  )}

                  {this.props.methods.includes('phone') && (
                     <div className={styles.method}>
                        <h3>Call Us</h3>
                        <p>
                           During our normal business hours of Monday &mdash;
                           Friday, 8:30 AM &mdash; 5:30 PM{' '}
                           <small>(excluding holidays!)</small>, our
                           human-centric team is available to take your call.
                        </p>
                        <div className={styles.action}>
                           <a
                              href="tel:+13017706800"
                              rel="noopener noreferrer"
                              className="button button--primary button--small button--has-icon button--has-icon">
                              Call Our Office <span>&rarr;</span>
                           </a>
                        </div>
                        <div className={styles.icon}>
                           <FontAwesomeIcon icon={faPhone} fixedWidth />
                        </div>
                     </div>
                  )}

                  {this.props.methods.includes('email') && (
                     <div className={styles.method}>
                        <h3>Email Us</h3>
                        <p>
                           If you would like to contact us outside of normal
                           business hours, we will respond to emails as soon as
                           possible.
                        </p>
                        <div className={styles.action}>
                           <Link
                              to="/contact/"
                              className="button button--white button--small button--has-icon button--has-icon">
                              Contact Us <span>&rarr;</span>
                           </Link>
                        </div>
                        <div className={styles.icon}>
                           <FontAwesomeIcon icon={faPaperPlane} fixedWidth />
                        </div>
                     </div>
                  )}

                  {this.props.methods.includes('linkedin') && (
                     <div className={styles.method}>
                        <h3>LinkedIn</h3>
                        <p>
                           Occasionally we post to our LinkedIn profile. Follow
                           us to help ensure you never miss out!
                        </p>
                        <div className={styles.action}>
                           <a
                              href="//linkedin.com/company/spc-financial-inc"
                              rel="noopener noreferrer"
                              target="_blank"
                              className="button button--primary button--small button--has-icon button--has-icon">
                              View Our Profile <span>&rarr;</span>
                           </a>
                        </div>
                        <div className={styles.icon}>
                           <FontAwesomeIcon icon={faLinkedin} fixedWidth />
                        </div>
                     </div>
                  )}

                  {this.props.methods.includes('directions') && (
                     <div className={styles.method}>
                        <h3>Our Office</h3>
                        <p>
                           From time to time you may need a reminder of our
                           address to mail documents or if you plan to visit our
                           office.
                        </p>
                        <div className={styles.action}>
                           <a
                              href="//google.com/maps/dir//SPC+Financial,+Inc.,+3202+Tower+Oaks+Blvd+%23400,+Rockville,+MD+20852/@39.0593281,-77.220709"
                              rel="noopener noreferrer"
                              target="_blank"
                              className="button button--white button--small button--has-icon button--has-icon">
                              Get Directions <span>&rarr;</span>
                           </a>
                        </div>
                        <div className={styles.icon}>
                           <FontAwesomeIcon icon={faCompass} fixedWidth />
                        </div>
                     </div>
                  )}
               </>
            ) : (
               <p>An error has occurred.</p>
            )}
         </section>
      )
   }
}

export default Support
