export const faqs = [
   {
      id: 0,
      question: 'What is Stripe?',
      category: ['general'],
      answer: ['Stripe is our third-party payment processing vendor.'],
      isActive: true,
   },
   {
      id: 1,
      question: 'Are there processing fees?',
      category: ['general'],
      answer: [
         'Clients will not be charged, and will not pay, any processing, credit, or debit card fees to SPC or Stripe. SPC pays Stripe to process our credit and debit card transactions.  SPC does not earn or receive any fees or other compensation from Stripe.',
      ],
      isActive: true,
   },
   {
      id: 2,
      question: 'Are Stripe and SPC affiliated?',
      category: ['general'],
      answer: [
         'No, SPC and Stripe are not affiliated. SPC does not earn or receive any fees or other compensation from Stripe.',
      ],
      isActive: true,
   },
   {
      id: 3,
      question: 'Who can I contact if I have questions?',
      category: ['general'],
      answer: [
         "There are several ways in which you can contact our office. We've listed the most common ways here on this page. If you're on a large screen, they'll be on the right or if you're on a smaller device, they can be found below these FAQs.",
      ],
      isActive: true,
   },
   {
      id: 4,
      question: 'Is Stripe secure?',
      category: ['security'],
      answer: [
         'Stripe is a cloud-based credit and debit card payment processor whose infrastructure runs within various Amazon Web Services data centers. These data centers have rigorous physical security standards and controls in place, with infrastructure dispersed throughout multiple geographic regions that replicate hourly for redundancy and continuity of services. Sensitive data such as card-holder data is encrypted at the database level using industry accepted AES-256 encryption. Stripe performs monthly internal/external vulnerability scans and quarterly penetration tests. All Stripe employees are required to satisfactorily pass criminal background checks and certain positions require recurring periodic checks. Stripe is a certified Level 1 PCI Compliant Provider, which is the most stringent certification available in the payment card industry.',
      ],
      isActive: true,
   },
   {
      id: 5,
      question: 'Do you store my credit card data?',
      category: ['security'],
      answer: [
         'No, we do not store information you enter in our online Invoice Payment system. No sensitive credit/debit card information ever touches our servers but instead is processed directly by Stripe.',
      ],
      isActive: true,
   },
   {
      id: 6,
      question: 'How is my data submitted protected?',
      category: ['security'],
      answer: [
         'The security of your personal information is important to us. Although we have industry-leading security standards in place, please remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.',
         "Regarding the security of credit/debit card information provided for payment: such sensitive information is not processed on or even touched by our servers. Instead, all of those requests route directly on Stripe's infrastructure.",
         "All of the pages on our website are protected by Transport Layer Security (TLS), a cryptographic protocol designed to provide communications security over a computer network. This can be identified by the 'HTTPS' in our website's URL. The TLS protocol aims primarily to provide privacy and data integrity between two or more communicating computer applications. Our TLS certificates have a SHA-256 with RSA Encryption algorithm and are provided by Let's Encrypt, a non-profit certificate authority operated by the Internet Security Research Group.",
         "*Please note that SPC is in no way affiliated with any of the companies or organizations listed in this section, including but not limited to: Let's Encrypt or the Internet Security Research Group.",
      ],
      isActive: true,
   },
   {
      id: 7,
      question: 'Can I still pay by phone?',
      category: ['alternate-payments'],
      answer: [
         'If you would prefer to process your credit or debit card information over the phone, please call our office. We will then process your information internally through Stripe.',
      ],
      isActive: true,
   },
   {
      id: 8,
      question: 'Can I email you my credit/debit card information?',
      category: ['alternate-payments'],
      answer: [
         'No, we recommend that you do not send us any credit or debit card information by email; we will not process it.',
      ],
      isActive: true,
   },
   {
      id: 9,
      question: 'Can I still pay by check?',
      category: ['alternate-payments'],
      answer: [
         'Yes, we will continue to accept checks as payment for your financial planning fees.',
      ],
      isActive: true,
   },
   {
      id: 10,
      question: 'Can I receive a paper invoice?',
      category: ['alternate-payments'],
      answer: [
         'If you would like to receive a copy of your invoice by U.S. mail, please let our office know.',
      ],
      isActive: true,
   },
]

export default {
   faqs,
}
