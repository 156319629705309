import React from 'react'
// import { Link } from 'gatsby'
import styles from './faq.module.sass'

import FAQs from '../../../components/payments/FAQs'
import Support from '../../../components/support'

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'

const methods = ['chat', 'phone', 'email']

class PaymentFAQPage extends React.Component {
   constructor() {
      super()
      this.state = { stripe: null }
   }

   render() {
      return (
         <Layout title="Payment FAQs">
            <SEO title="Payment FAQs" />
            <div className={['page', styles.page__help].join(' ')}>
               <div className={['page--header', styles.page__header].join(' ')}>
                  <h1 className={['page--title', styles.page__title].join(' ')}>
                     Payment FAQs
                  </h1>
                  <p
                     className={[
                        'page--subheader',
                        styles.page__subheader,
                     ].join(' ')}>
                     Here, we have compiled a list of some of the most commonly
                     asked questions regarding payments.
                     <br />
                     Additionally, below are the most pertinent ways to contact
                     our office for payments questions.
                  </p>
               </div>
               <div className={styles.content}>
                  <div className={styles.questions}>
                     <FAQs />
                  </div>

                  <div className={styles.help_methods}>
                     <Support methods={methods} invertColors />
                  </div>
               </div>
            </div>
         </Layout>
      )
   }
}

export default PaymentFAQPage
