import React, { Component } from 'react'

import {
   Accordion,
   AccordionItem,
   AccordionItemHeading,
   AccordionItemButton,
   AccordionItemState,
   AccordionItemPanel,
} from 'react-accessible-accordion'

import { faqs } from './FAQData'

import styles from './faq.module.sass'

function AccordionSection(props) {
   return (
      <Accordion
         allowZeroExpanded="true"
         preExpanded={['001']}
         className={styles.main__accordion}>
         <AccordionItem uuid="001">
            <AccordionItemHeading>
               <AccordionItemButton className={styles.main__button}>
                  <h3>
                     &mdash; <span>{props.title}</span> &mdash;
                  </h3>
               </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={styles.main__panel}>
               <div className={styles.accordions}>{props.children}</div>
            </AccordionItemPanel>
         </AccordionItem>
      </Accordion>
   )
}

export default class FAQs extends Component {
   constructor(props) {
      super(props)
      this.state = { accordions: [] }
   }

   render() {
      return (
         <div className={styles.faqs}>
            <div className={styles.inner}>
               <AccordionSection title="General Questions">
                  {faqs.map(
                     (faq, i) =>
                        faq.isActive &&
                        faq.category.includes('general') && (
                           <Accordion
                              key={faq.id}
                              allowZeroExpanded="true"
                              className={styles.accordion}
                              onChange={(itemUuids) => {
                                 let uid = itemUuids
                                 let accordions = this.state.accordions
                                 this.setState({ accordions: [] })

                                 accordions[uid] =
                                    uid && uid !== '' && uid !== null
                                       ? false
                                       : true
                                 this.setState({
                                    accordions: this.state.accordions,
                                 })
                                 // console.log(uid, this.state.accordions)
                              }}>
                              <AccordionItem
                                 className={[
                                    styles.accordion__item,
                                    this.state.accordions[faq.id]
                                       ? styles.expanded
                                       : '',
                                 ].join(' ')}>
                                 <AccordionItemHeading>
                                    <AccordionItemButton
                                       className={styles.accordion__button}>
                                       <span>{faq.question}</span>
                                       <AccordionItemState>
                                          {/* Set state for which accordions are expanded */}
                                          {(state) => {
                                             this.state.accordions[i] =
                                                state.expanded
                                          }}
                                       </AccordionItemState>
                                    </AccordionItemButton>
                                 </AccordionItemHeading>
                                 <AccordionItemPanel
                                    className={styles.accordion__panel}>
                                    <div className={styles.answer}>
                                       {faq.answer.map((a, i) => (
                                          <p key={i}>{a}</p>
                                       ))}
                                    </div>
                                 </AccordionItemPanel>
                              </AccordionItem>
                           </Accordion>
                        )
                  )}
               </AccordionSection>

               <AccordionSection title="Security Questions">
                  {faqs.map(
                     (faq, i) =>
                        faq.isActive &&
                        faq.category.includes('security') && (
                           <Accordion
                              key={faq.id}
                              allowZeroExpanded="true"
                              className={styles.accordion}
                              onChange={(itemUuids) => {
                                 let uid = itemUuids
                                 let accordions = this.state.accordions
                                 this.setState({ accordions: [] })

                                 accordions[uid] =
                                    uid && uid !== '' && uid !== null
                                       ? false
                                       : true
                                 this.setState({
                                    accordions: this.state.accordions,
                                 })
                                 // console.log(uid, this.state.accordions)
                              }}>
                              <AccordionItem
                                 className={[
                                    styles.accordion__item,
                                    this.state.accordions[faq.id]
                                       ? styles.expanded
                                       : '',
                                 ].join(' ')}>
                                 <AccordionItemHeading>
                                    <AccordionItemButton
                                       className={styles.accordion__button}>
                                       <span>{faq.question}</span>
                                       <AccordionItemState>
                                          {/* Set state for which accordions are expanded */}
                                          {(state) => {
                                             this.state.accordions[i] =
                                                state.expanded
                                          }}
                                       </AccordionItemState>
                                    </AccordionItemButton>
                                 </AccordionItemHeading>
                                 <AccordionItemPanel
                                    className={styles.accordion__panel}>
                                    <div className={styles.answer}>
                                       {faq.answer.map((a, i) => (
                                          <p key={i}>{a}</p>
                                       ))}
                                    </div>
                                 </AccordionItemPanel>
                              </AccordionItem>
                           </Accordion>
                        )
                  )}
               </AccordionSection>

               <AccordionSection title="Alternate Payment Methods">
                  {faqs.map(
                     (faq, i) =>
                        faq.isActive &&
                        faq.category.includes('alternate-payments') && (
                           <Accordion
                              key={faq.id}
                              allowZeroExpanded="true"
                              className={styles.accordion}
                              onChange={(itemUuids) => {
                                 let uid = itemUuids
                                 let accordions = this.state.accordions
                                 this.setState({ accordions: [] })

                                 accordions[uid] =
                                    uid && uid !== '' && uid !== null
                                       ? false
                                       : true
                                 this.setState({
                                    accordions: this.state.accordions,
                                 })
                                 // console.log(uid, this.state.accordions)
                              }}>
                              <AccordionItem
                                 className={[
                                    styles.accordion__item,
                                    this.state.accordions[faq.id]
                                       ? styles.expanded
                                       : '',
                                 ].join(' ')}>
                                 <AccordionItemHeading>
                                    <AccordionItemButton
                                       className={styles.accordion__button}>
                                       <span>{faq.question}</span>
                                       <AccordionItemState>
                                          {/* Set state for which accordions are expanded */}
                                          {(state) => {
                                             this.state.accordions[i] =
                                                state.expanded
                                          }}
                                       </AccordionItemState>
                                    </AccordionItemButton>
                                 </AccordionItemHeading>
                                 <AccordionItemPanel
                                    className={styles.accordion__panel}>
                                    <div className={styles.answer}>
                                       {faq.answer.map((a, i) => (
                                          <p key={i}>{a}</p>
                                       ))}
                                    </div>
                                 </AccordionItemPanel>
                              </AccordionItem>
                           </Accordion>
                        )
                  )}
               </AccordionSection>
            </div>
         </div>
      )
   }
}
